var render = function () {
  var _vm$formSubcat, _vm$formBobot;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Harap pahami dahulu alur rasionalisasi Bintara "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        var _vm$$route$params;
        $event.preventDefault();
        return _vm.$router.push("/master-admin/rasionalisasi/bintara?p=".concat((_vm$$route$params = _vm.$route.params) === null || _vm$$route$params === void 0 ? void 0 : _vm$$route$params.id));
      }
    }
  }, [_vm._v("disini.")])])]), _c('b-card', [_c('header', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Silahkan tentukan "), _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("passing grade")]), _vm._v(" untuk setiap Sub Kategori")])]), _c('section', {
    staticClass: "mb-2"
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.subCatFields,
      "items": _vm.subCategories
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('strong', [_c('a', {
          staticClass: "text-info",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.editPGSubcat(item);
            }
          }
        }, [_vm._v("Edit")])])];
      }
    }])
  })], 1), _c('header', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Silahkan tentukan "), _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("persentase bobot")]), _vm._v(" ujian-ujian di setiap sub kategori")])]), _c('table', {
    staticClass: "table table-bordered w-100",
    attrs: {
      "border": "1"
    }
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Nama Ujian")]), _c('th', [_vm._v("Bobot Persentase")]), _c('th', [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.subtests, function (item, i) {
    var _item$ujian, _item$bobot;
    return _c('tr', {
      key: i
    }, [item.is_parent ? _c('td', {
      attrs: {
        "colspan": "4"
      }
    }, [_c('i', {
      staticClass: "text-dark"
    }, [_vm._v(_vm._s(item.name) + ": Total Bobot ("), _c('strong', {
      class: item.total_percentage == 100 ? 'text-success' : ''
    }, [_vm._v(_vm._s(item.total_percentage) + "%")]), _vm._v(") "), item.total_percentage != 100 ? _c('small', {
      staticClass: "text-danger"
    }, [_vm._v("*pastikan total bobot sub kategori 100%")]) : _vm._e()])]) : _vm._e(), !item.is_parent ? _c('th', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.index) + " ")]) : _vm._e(), !item.is_parent ? _c('th', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$ujian = item.ujian) === null || _item$ujian === void 0 ? void 0 : _item$ujian.name) + " ")]) : _vm._e(), !item.is_parent ? _c('th', [_c('strong', {
      staticClass: "text-primary"
    }, [_vm._v(_vm._s((_item$bobot = item.bobot) === null || _item$bobot === void 0 ? void 0 : _item$bobot.value_percentage) + "%")])]) : _vm._e(), !item.is_parent ? _c('th', [_c('strong', [_c('a', {
      staticClass: "text-info",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.editBobot(item);
        }
      }
    }, [_vm._v("Edit")])])]) : _vm._e()]);
  }), 0)])]), _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "title": "Edit Passing Grade Sub Kategori",
      "id": "modal-subcat"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.updatePassingGrade($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Sub Kategori: "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s((_vm$formSubcat = _vm.formSubcat) === null || _vm$formSubcat === void 0 ? void 0 : _vm$formSubcat.name))])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Passing Grade (1 - 100)")]), _c('b-form-input', {
    model: {
      value: _vm.formSubcat.passing_grade,
      callback: function callback($$v) {
        _vm.$set(_vm.formSubcat, "passing_grade", $$v);
      },
      expression: "formSubcat.passing_grade"
    }
  })], 1)]), _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "id": "modal-bobot",
      "title": "Atur Bobot Persentase Subtest"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.updateBobot($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('section', [_c('h5', {
    staticClass: "mb-1"
  }, [_c('i', [_vm._v("Ujian: "), _c('strong', [_vm._v(_vm._s((_vm$formBobot = _vm.formBobot) === null || _vm$formBobot === void 0 ? void 0 : _vm$formBobot.name))])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_c('strong', [_vm._v("Bobot Ujian (dalam %)")])]), _c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.formBobot.value_percentage,
      callback: function callback($$v) {
        _vm.$set(_vm.formBobot, "value_percentage", $$v);
      },
      expression: "formBobot.value_percentage"
    }
  })], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }