var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "centered": "",
      "size": "sm",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.Tambah($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tutupModal($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.Modal,
      callback: function callback($$v) {
        _vm.Modal = $$v;
      },
      expression: "Modal"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Bobot",
      "label-for": "Bobot"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "Bobot",
      "placeholder": "Bobot"
    },
    model: {
      value: _vm.form.bobot,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bobot", $$v);
      },
      expression: "form.bobot"
    }
  })], 1)], 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Harap pahami dahulu alur rasionalisasi SMA "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        var _vm$$route$params;
        $event.preventDefault();
        return _vm.$router.push("/master-admin/rasionalisasi/sma?p=".concat((_vm$$route$params = _vm.$route.params) === null || _vm$$route$params === void 0 ? void 0 : _vm$$route$params.id));
      }
    }
  }, [_vm._v("disini.")])])]), _c('b-card', [_c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('header', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v("Silahkan tentukan nilai penghitung hasil ujian siswa di rasionalisasi SMA.")])]), _c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bobot",
      "label-for": "bobot"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Bobot",
      "name": "bobot",
      "type": "number"
    },
    model: {
      value: _vm.form.bobot,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bobot", $$v);
      },
      expression: "form.bobot"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-2 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.Tambah($event);
      }
    }
  }, [_vm._v(" Simpan perubahan ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }